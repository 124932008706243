<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
      <template slot="footer">
        <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
        <a-form-model-item label="监控端名称" prop="monitorname">
          <a-input v-model.trim="formDatas.monitorname" :disabled="showType=='detail'" />
        </a-form-model-item>
        <a-form-model-item label="监控端ID" prop="monitornum">
          <a-input v-model.trim="formDatas.monitornum" :disabled="showType=='detail'" />
        </a-form-model-item>
        <a-form-model-item label="监控端类型" prop="monitortype">
          <a-select v-model="formDatas.monitortype" :disabled="showType=='detail'">
            <a-select-option v-for="(item, index) in monitorTypes" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="monitordecs">
          <a-input v-model.trim="formDatas.monitordecs" :disabled="showType=='detail'" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <select-coordinate :visible.sync="selectCoordinateVisible" @get-coordinate="getCoordinate"></select-coordinate>
  </div>
</template>
<script>
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {addMonitorinfo, modifyMonitorinfo} from "A/monitoring";
import SelectCoordinate from 'C/modals/SelectCoordinate.vue'
import {mapState} from "vuex";
import {getChildTreeListByParentId} from "A/jcgn";
import {monitorTypes} from "@/json/ai";
export default {
  components: {
    SelectCoordinate,
  },
  mixins: [areaselect,deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    areaLists: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      modalVisible: false,
      selectCoordinateVisible: false,
      userdepidCascaderSelected: [],
      areaCascaderSelected:[],
      monitorTypes,
      formDatas: {
        monitorname: '',
        monitornum: '',
        monitordecs: '',
        isremote:'',
        monitortype:null,
      },
      formRules: {
        monitorname: [{required: true, message: '请输入项目名称'}],
        monitornum: [{required: true, message: '请输入项目编号'}],
        monitortype: [{required: true, message: '请选择监控端类型'}],
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }else {
        this.formDatas.areacode = '';
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length - 1];
        this.$refs.modalForm.clearValidate('userdepid')
      }else {
        this.formDatas.userdepid = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll()
  },
  methods: {
    initDeptOptionsAll(parentid='') {
      let params = {
        parentid,
        deptype: this.initDeptType,
        isall: 'true'
      };
      getChildTreeListByParentId(params).then(res => {
        if(res && res.returncode == '0') {
          this.deptOptionsAll = res.item.map(item => {
            item.userdepid = ''+item.userdepid;
            item.isLeaf = item.childsize === 0;
            return item;
          });
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            if(key=="isremote"){
              this.formDatas.isremote=this.detailData.isremote
            }else if(key=="monitortype"){
              this.formDatas.monitortype=this.detailData.monitortype
            }else{
              this.formDatas[key] = this.detailData[key]||'';
            }
          }
        })
      }
    },
    resetDatas() {
      this.$refs.modalForm.resetFields();
      this.areaCascaderSelected=[]
      this.userdepidCascaderSelected=[]
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas,
              monitorinfoid: this.detailData?this.detailData.monitorinfoid:null
            };
            if(this.showType == 'add') {
              addMonitorinfo(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyMonitorinfo(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    getCoordinate(coordinate) {
      this.formDatas.coordinatex = coordinate[0];
      this.formDatas.coordinatey = coordinate[1];
    },
  }
}
</script>