export const checkstatusOptions = [
  {
    value: '01',
    text: '正常'
  },
  {
    value: '02',
    text: '故障'
  },
  {
    value: '03',
    text: '无信号'
  },
];

export const checkstatusColorMap = {
  '01': 'green',
  '02': 'red',
}

export const monitorTypes = [
  {key: 0, value: '监控中心'},
  {key: 1, value: '管理计算机'},
]